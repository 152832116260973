

.parent {
  padding-top: 24px;
  display: flex;
  flex-flow: column;
  align-items: center;
  background: #F8F8F8;
  color: #999999;
  font-size: 14px;

  .name {
    font-size: 16px;
    color: #333333;
    font-weight: bold;
  }
}

.mainImage {
  background: white;
  width: 320px;
  height: 320px;
  border-radius: 6px;
}

.smallImage {
  width: 48px;
  display: block;
  height: 48px;
}

.colorImage {
  width: 36px;
  height: 36px;
}

.detailsImage {
  width: 100%;
  display: block;
}

