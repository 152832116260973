

.about_text1 {
  display: flex;
  font-size: 18px;
  background: #CB1F20;
  justify-content: center;
  padding: 48px 12px;
  align-items: center;
  color: white;
  flex: 1;
  flex-flow: column;
  text-align: center
}

.about_text2 {
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  padding: 48px 12px;
  color: #333333;
  align-items: center;
  background: rgba(255, 255, 255, 0.9);
  flex: 1;
  flex-flow: column;
  text-align: center
}

.title_image {
  height: 54px;
  width: 396px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.image_line {
  background-color: #CB1F20;
  height: 8px;
  width: 68px;
  margin-top: 24px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.hot_style {
  box-sizing: border-box;
  width: 100%;
  justify-content: center;
  display: flex;

  .item_content {
    width: 100%;
    background: #CB1F20;
    text-align: center;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 300;
    font-size: 22px;
    line-height: 80px;
    color: #FFFFFF;
    font-style: italic;
  }

  .image {
    width: 100%;
    background: white;
    object-fit: fill;
    height: 576px;
  }

  .item1 {
    flex-grow: 2;
    margin: 6px;
    display: flex;
    flex-flow: column;
    max-width: 920px;
  }

  .item2 {
    margin: 6px;
    flex-grow: 1;
    display: flex;
    flex-flow: column;
    max-width: 454px;
  }

  .item4 {
    flex-grow: 1;
    display: flex;
    margin: 6px;
    flex-flow: column;
    max-width: 677px;

    .image {
      width: 677px;
      height: 1032px;
    }
  }

  .item5 {
    flex-grow: 1;
    display: flex;
    margin: 6px;
    flex-flow: column;
    max-width: 692px;

    .image {
      width: 692px;
      height: 394px;
    }
  }

  .item6 {
    flex-grow: 1;
    display: flex;
    margin: 6px;
    flex-flow: column;
    max-width: 692px;

    .image {
      width: 692px;
      height: 624px
    }
  }

  .item7 {
    flex-grow: 1;
    display: flex;
    margin: 6px;
    flex-flow: column;
    max-width: 436px;

    .image {
      width: 436px;
      height: 295px;
    }
  }

  .item8 {
    flex-grow: 1;
    display: flex;
    margin: 6px;
    flex-flow: column;
    max-width: 935px;

    .image {
      width: 935px;
      height: 604px;
    }
  }
}
