

.banner {
  display: flex;
  gap: 12px;
}

.parent {
  display: flex;
  flex-flow: column;
  align-items: center;
  overflow: auto;
  word-break: break-word;
}

.about_us {
  display: flex;
  margin: 24px;
  width: 900px;

  .image {
    width: 400px;
    position: relative;
    height: 310px;
  }

  .image1 {
    width: 300px;
    height: 200px;
  }

  .image2 {
    width: 300px;
    height: 200px;
    position: absolute;
    bottom: 0;
    right: 0
  }

  .content1 {
    white-space: pre-wrap;
    text-align: justify;
    margin-left: 36px;
    width: 450px;
    font-size: 14px;
    color: #333333;
    font-style: italic;
    max-width: 450px;
  }
}

.content2 {
  width: 900px;
  margin-bottom: 36px;
  white-space: pre-wrap;
  text-align: justify;
  font-size: 14px;
  color: #333333;
  font-style: italic;
}

.linglong {
  width: 100%;
  background: url('../assets/images/aboutus2.png');
  background-size: cover;
  box-sizing: border-box;
  padding: 36px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  .name {
    font-weight: 400;
    margin-bottom: 36px;
    font-size: 30px;
    color: #FFFFFF;
    font-style: italic;
  }

  .item1 {
    padding: 16px;
    width: 240px;
    border: 1px solid #666666;
  }

  .item2 {
    padding: 16px;
    width: 240px;
    border-top: 1px solid #666666;
    border-bottom: 1px solid #666666;
    border-right: 1px solid #666666;
  }

  .item3 {
    padding: 16px;
    width: 240px;
    border-top: 1px solid #666666;
    border-bottom: 1px solid #666666;
  }

  .num {
    color: #CB1F20;
    font-weight: bold;
    font-size: 16px;
    font-style: italic;
  }

  .title {
    font-weight: 400;
    font-size: 20px;
    color: #FFFFFF;
    margin: 18px 0;
    font-style: italic;
  }

  .desc {
    font-weight: 400;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);
    font-style: italic;
  }
}

.image3 {
  width: 250px;
  margin: 36px;
  height: 300px;
}

.history_image {
  width: 60%;
}

.title_image {
  height: 28px;
}

.title1 {
  font-size: 24px;
  color: #333333;
  font-style: italic;
  margin: 24px 0;
}

.desc3 {
  font-weight: 400;
  width: 1096px;
  font-size: 16px;
  white-space: pre-wrap;
  text-align: justify;
  color: rgba(255, 255, 255, 0.7);
  font-style: italic;
}

