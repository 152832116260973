
.head {
  display: flex;
  padding: 16px;
  align-items: center;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.8);

  :deep(.el-button) {
    font-weight: 400;
    font-size: 20px;
  }

  .logo {
    box-sizing: border-box;
    padding: 3px;
    height: 36px;
  }
}

.foot {
  width: 100%;
  padding: 24px 36px 12px;
  box-sizing: border-box;
  background: #2A2A2A;

  .title {
    font-weight: 800;
    font-size: 32px;
    color: #FFFFFF;
    font-style: italic;
  }

  .left {
    box-sizing: border-box;
    max-width: 50%;
    flex-grow: 1;

    .subtitle {
      font-weight: 300;
      margin-top: 24px;
      font-size: 16px;
      color: #FFFFFF;
      font-style: italic;
    }

    .content {
      margin-top: 3px;
      font-weight: 400;
      font-size: 20px;
      color: #FFFFFF;
      font-style: italic;
    }

    .call_icon {
      margin-right: 6px;
      margin-top: 4px;
      width: 24px;
      height: 24px;
    }

    .phone {
      font-weight: 400;
      font-size: 32px;
      color: #FFFFFF;
    }
  }

  .right {
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    max-width: 50%;
    flex-grow: 1;

    .subtitle {
      font-weight: 300;
      margin-top: 18px;
      font-size: 16px;
      color: #FFFFFF;
      font-style: italic;
    }

    .input {
      margin-top: 6px;
      width: 50%;
      color: white;
    }

    :deep(.el-input__wrapper) {
      background: transparent;
      height: 36px;
      border-radius: 6px;
    }

    :deep(.el-textarea__inner) {
      background: transparent;
      border-radius: 6px;
    }

    :deep(.el-input__inner) {
      color: white;
    }

    :deep(.el-textarea__inner) {
      color: white;
    }

    .button {
      font-weight: 400;
      width: 164px;
      font-size: 20px;
      margin-top: 24px;
      padding: 20px 24px;
      outline-width: 0;
      color: white;
      background: #007DE7;
    }
  }

  .bottom {
    margin-top: 36px;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    color: #6F6F6F;
  }
}

