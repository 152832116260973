

.search_input {
  margin: 36px 24px 24px 24px;
  font-size: 20px;
  width: 600px;
  height: 60px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.product {
  display: flex;
  flex-flow: row;
  gap: 24px;

  .item {
    background: white;
    text-align: center;
    padding: 6px;
    box-sizing: border-box;
    margin: 12px 0;

    .image {
      background: #F8F8F8;
      display: block;
      width: 418px;
      height: 418px;
    }

    .title {
      color: #333333;
      max-width: 418px;
      padding: 12px;
      box-sizing: border-box;
      font-size: 24px;
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .subtitle {
      max-width: 418px;
      color: #666666;
      box-sizing: border-box;
      height: 20px;
      font-size: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .more_button {
      font-size: 20px;
      margin-top: 24px;
      margin-bottom: 18px;
      color: white;
      background: #CB1F20;
      border-color: transparent;
      border-radius: 2px;
      padding: 20px 24px;

      .icon {
        margin-left: 3px;
        width: 20px;
        height: 20px;
      }
    }
  }
}

:deep(.el-carousel__arrow) {
  display: none;
}
